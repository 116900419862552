import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Contact from "../components/contact"


const KontaktPage = () => (
  <div>
  <Seo title="Kontakt - Altergott Studios" lang="de" description="Wir beantworten gerne Ihre Fragen und freuen uns auf neue Kontakte!" />
  <Layout>
      <Contact />
  </Layout>
    
    
  </div>
)

export default KontaktPage